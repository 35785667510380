import {
  useSession,
  useSessionContext,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import Link from "next/link";
import logger from "@/utils/logger";
import analytics from "@/utils/analytics/analytics";

export default function Login() {
  const session = useSession();
  const sessionContext = useSessionContext();
  const supabase = useSupabaseClient();
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const forceLogin = localStorage.getItem("forceLogin");
    if (!!forceLogin) {
      localStorage.removeItem("forceLogin");
      supabase.auth.signOut();
    }

    if (!forceLogin && session) {
      const { redirectTo } = router.query;
      if (redirectTo) {
        router.push(redirectTo as string);
      } else {
        router.push("/app/records");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  async function handleLogin() {
    if (!email || !password) {
      toast.error("Please fill out all fields");
      return;
    }

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      toast.error(error.message);
    } else {
      logger.log("users-login", `User logged in`, `User ${email} logged in`);
      analytics.track("login", {
        provider: "email",
      });

      router.push("/app/records");
    }
  }

  async function handleLoginGoogle() {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: `https://myjson.online/app/records`,
      },
    });
    if (error) {
      toast.error(error.message);
    } else {
      logger.log("users-login", `User logged in`, `with google`);
      analytics.track("login", {
        provider: "google",
      });

      router.push("/app/records");
    }
  }

  if (sessionContext.isLoading || !!session) {
    return (
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen bg-black" />
    );
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-black h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-full flex justify-center">
          <Link href="/">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src="/logo.svg" alt="Back to main page" className="h-12" />
          </Link>
        </div>
        <h2 className="mt-12 text-center text-3xl font-bold tracking-tight text-gray-200">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-200">
          Or{" "}
          <Link href="/signup" className="font-medium text-purple">
            create your account
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-bg-gray py-8 px-4 shadow sm:rounded-lg sm:px-10 rounded-md border-b-gray border">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-200"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full rounded-md border bg-transparent px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple focus:outline-none focus:ring-purple sm:text-sm text-white"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-200"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                  autoComplete="current-password"
                  required
                  className="w-full rounded-md border bg-transparent px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple focus:outline-none focus:ring-purple sm:text-sm text-white"
                />
              </div>
            </div>

            <div>
              <button
                className="flex w-full justify-center rounded-md border border-transparent bg-purple py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-purple focus:ring-offset-2"
                onClick={handleLogin}
              >
                Sign in
              </button>
            </div>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-bg-gray px-2 text-gray-400">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6">
              <div>
                <button
                  className="inline-flex w-full justify-center rounded-md border-gray-500 border py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-800"
                  onClick={handleLoginGoogle}
                >
                  <span className="sr-only">Sign in with Google</span>
                  <svg
                    height={20}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 210 210"
                  >
                    <path
                      d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40
	c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105
	S0,162.897,0,105z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="mt-4">
              <Link
                href="/reset-password"
                className="flex justify-center text-sm font-medium text-gray-400 hover:text-gray-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
