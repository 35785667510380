import { Logger } from "./logger";
import LogSnag from "logsnag";

export default function Logsnag(key: string): Logger {
  const logsnag = new LogSnag({
    token: key,
    project: "myjson",
  });

  return {
    log(channel: string, message: string, description?: string) {
      logsnag.publish({
        channel,
        event: message,
        description,
        notify: true,
      });
    },
  };
}
